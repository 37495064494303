import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import logo from "./logo.png";

import img1 from './img/1.webp';
import img2 from './img/2.webp';
import img3 from './img/3.webp';
import img4 from './img/4.webp';
import img5 from './img/5.webp';
import img6 from './img/6.webp';
import img7 from './img/7.webp';
import img8 from './img/8.webp';
import img9 from './img/9.webp';
import img10 from './img/10.webp';
import img11 from './img/11.webp';
import img12 from './img/12.webp';
import img13 from './img/13.webp';
import img14 from './img/14.webp';
import img15 from './img/15.webp';
import img16 from './img/16.webp';
import img17 from './img/17.webp';
import img18 from './img/18.webp';
import img19 from './img/19.webp';
import img20 from './img/20.webp';
import dogringa1 from './imgs/dogringa (1).png';
import dogringa2 from './imgs/dogringa (2).png';
import dogringa3 from './imgs/dogringa (3).png';
import dogringa4 from './imgs/dogringa (4).png';
import dogringa5 from './imgs/dogringa (5).png';
import dogringa6 from './imgs/dogringa (6).png';
import dogringa7 from './imgs/dogringa (7).png';
import dogringa8 from './imgs/dogringa (8).png';
import dogringa9 from './imgs/dogringa (9).png';
import dogringa10 from './imgs/dogringa (10).png';
import dogringa11 from './imgs/dogringa (11).png';
import dogringa12 from './imgs/dogringa (12).png';
import morcego from "./morcego.gif";
import molde from "./molde.webp";

const accessKey = 'A1qQaAA9kdfnn4Mmn44bpoieIYHKkdghFKUD1978563llakLLLKdfslphgarcorc3haeogmmMNn243wf';

function App() {
  const images = [
    img1, img2, img3, img4, img5,
    img6, img7, img8, img9, img10,
    img11, img12, img13, img14, img15,
    img16, img17, img18, img19, img20
  ];

  const tickers = [
    dogringa1, dogringa2, dogringa3, dogringa4, dogringa5,
    dogringa6, dogringa7, dogringa8, dogringa9, dogringa10,
    dogringa11, dogringa12
  ];

  const tickerPositions = [
    { top: '10%', left: '10%' },
    { top: '20%', left: '25%' },
    { top: '45%', left: '27%' },
    { top: '40%', left: '70%' },
    { top: '50%', left: '90%' },
    { top: '45%', left: '5%' },
    { top: '70%', left: '20%' },
    { top: '70%', left: '65%' },
    { top: '70%', left: '80%' },
    { top: '10%', left: '80%' },
    { top: '10%', left: '65%' },
    { top: '70%', left: '10%' }
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://interca.onrender.com/api/purchaseData', {
          headers: {
            'x-access-key': accessKey,
          },
        });
        setData(response.data);
      } catch (error) {
        setError('Erro ao buscar os dados. Verifique a chave de acesso e tente novamente.');
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(index => (index + 1) % images.length);
    }, 500); // Muda a imagem a cada 500ms
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="App">
      <div className="tokenCa">
        CA: {data.tokenCA || 'pump'}
      </div>
      <div className="back"></div>
      <div className="sec1">
        <div className="infos">
          <div className="lin">
            <a href={data.twitterLink || '#'} className="link">
              X/Twitter
            </a>
            <a href={data.telegramLink || '#'} className="link">
              Community
            </a>
          </div>
          <div className="links">
            <a href={data.link || '#'} className="link buy">
              BUY
            </a>
          </div>
        </div>
        <div className="title">{data.tokenName ? `This is ${data.tokenName} land` : 'This is dog land'}</div>
        <img className='logo' src={logo} alt="" />
        <img className='morcego' src={morcego} alt="" />
        <img className='morcego r' src={morcego} alt="" />
      </div>
      <div className="sec2">
        <img className='molde' src={molde} alt="" />
        <img src={images[currentImageIndex]} alt="" className="carousel-image" />
        {tickers.map((ticker, index) => (
          <img
            key={index}
            src={ticker}
            alt=""
            className="ticker-image"
            style={{ ...tickerPositions[index] }}
          />
        ))}
      </div>
      <div className="footer">
        All Right Reserved - 2024 - $SIGLADOTOKEN
      </div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
}

export default App;
